.my-account-container {
  width: 100%;
  height: max-content;
  padding: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;

  .wallet-icon {
    width: 38px;
    height: 38px;
  }

  .wallet-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wallet-info {
    border-radius: 4px;
    border: 1px solid var(--gray-50);
    width: 180px;
    height: 44px;
    text-decoration: none;
    background-color: white;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-500);

    p {
      font-size: 14px;
      padding-left: 7px;
    }

    &:hover {
      text-decoration: none;
      color: var(--primary-600);
    }
  }
  .my-account-header {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  .info-wrapper {
    display: flex;
    width: 100%;
    height: max-content;
    column-gap: 12px;
    justify-content: space-between;
    flex-direction: row;
    row-gap: 24px;

    .profile-info {
      width: max-content;

      .badge-wrapper {
        display: flex;

        .user-account-no-avatar-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 44px;

          .user-account-no-avatar {
            width: 128px;
            height: 128px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            background-color: var(--primary-25);

            svg {
              width: 55%;
              height: 55%;
            }
          }
        }

        .user-credentials {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          align-items: flex-start;
          color: var(--gray-900);

          .print-badge-wrapper {
            width: 100%;
            display: flex;
            gap: 12px;

            &.disabled {
              cursor: not-allowed;
            }

            & button:disabled {
              background-color: var(--gray-50);
              color: var(--gray-200);
            }

            .print-badge-button {
              box-shadow: 0 1px 2px rgba(16 24 40 / 5%);
              border-radius: 4px;
              height: 36px;
              text-transform: none;
              background-color: var(--primary-50);
              width: max-content;
              min-width: 128px;

              .MuiButton-label {
                display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }

              &:hover {
                background-color: var(--primary-700) !important;
              }
            }
          }

          .user-id {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }

          .user-name {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
            display: flex;
            column-gap: 12px;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }

          .user-email {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
          }

          .user-barcode {
            margin-left: -10px;
            margin-top: 14px;
          }
        }
      }
    }

    .profile-stats {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      width: max-content;

      .segment {
        width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        height: 170px;
        position: relative;

        .segment-analysis {
          z-index: 3 !important;
          width: max-content;
          max-width: 320px;
          box-shadow: 0 24px 48px -12px rgba(16 24 40 / 25%);
          background-color: rgba(255 255 255 / 85%);
          backdrop-filter: blur(3px);
          position: absolute;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          padding: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--primary-600);
          height: max-content;
          min-height: 170px;

          .analysis-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .close-analysis {
              color: var(--gray-900);
              cursor: pointer;
            }
          }

          .analysis-content {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            color: var(--gray-600);

            .analysis-row {
              color: var(--gray-600);
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              > :first-child {
                margin-right: 40px;
              }

              &.id {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
              }

              &.main-info {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--gray-700);
              }
            }
          }
        }

        .segment-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--gray-300);
          display: flex;
          justify-content: center;
          z-index: 1;
        }

        .plan-cost-wrapper {
          color: var(--gray-700);
          font-style: normal;
          font-weight: 700;
          max-width: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          row-gap: 13px;
          z-index: 1;
          margin-bottom: 15px;

          .plan-details {
            font-size: 20px;
            line-height: 30px;
            text-overflow: ellipsis;
            text-align: center;
            overflow: hidden;
            height: 30px;
            white-space: nowrap;
            width: 100%;
          }

          .plan-cost {
            font-size: 24px;
            line-height: 32px;
            text-align: center;
          }
        }

        .info-preview-wrapper {
          z-index: 1;
          position: relative;
          width: 155px;

          .ammount-preview {
            width: 100%;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--gray-900);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 10px;
          }
        }

        .analysis-toggle {
          z-index: 1;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--primary-700);

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .myaccount-divider {
    margin: 36px 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-100);
  }

  .info-fields-display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    column-gap: 48px;
    justify-content: space-between;

    .user-field {
      min-width: 256px;
      width: 100%;
      max-width: calc(50% - 48px);
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .field-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-900);
      }

      .field-value {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--gray-700);

        .password-reset-prompt {
          cursor: pointer;
          color: var(--primary-600);
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          &:hover {
            color: var(--primary-700);
          }
        }
      }
    }
  }

  .process-info-wrapper {
    margin-top: 48px;
    width: 100%;
    display: flex;

    .print-badge-button {
      display: none;
      box-shadow: 0 1px 2px rgba(16 24 40 / 5%);
      border-radius: 4px;
      height: 36px;
      text-transform: none;
      background-color: var(--primary-50);
      color: var(--primary-700);
      width: max-content;
      min-width: 128px;

      .MuiButton-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        background-color: var(--primary-100);
        color: var(--primary-800);
      }
    }

    .print-badge-button-mobile {
      display: none;
    }

    .process-info-button {
      min-width: 76px;
      padding: 8px 14px;
      box-shadow: none;
      border-radius: 4px;
      height: 36px;
      text-transform: none;
      background-color: var(--primary-600);
      color: white;
      width: max-content;

      .MuiButton-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.edit-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiBackdrop-root.backdrop-edit-info {
    backdrop-filter: blur(5px);
  }

  .edit-info-wrapper {
    background-color: white;
    box-shadow: 0 20px 24px -4px rgba(16 24 40 / 10%),
      0 8px 8px -4px rgba(16 24 40 / 4%);
    border-radius: 12px;
    padding: 24px;
    width: 95%;
    height: 95%;

    .edit-info-header {
      display: flex;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--gray-900);

      .close-edit-info {
        color: var(--gray-900);
        cursor: pointer;
      }
    }

    .modal-content-wrapper {
      margin: 40px 0;
      display: flex;
      column-gap: 42px;
      height: calc(100% - 154px);

      .account-profile {
        display: flex;
        flex-direction: column;
        width: max-content;
        height: max-content;
        row-gap: 18px;

        .update-profile-button {
          box-shadow: 0 1px 2px rgba(16 24 40 / 5%);
          border-radius: 4px;
          height: 36px;
          text-transform: none;
          background-color: var(--primary-50);
          color: var(--primary-700);
          width: 100%;

          .MuiButton-label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }

          &:hover {
            background-color: var(--primary-100);
            color: var(--primary-800);
          }
        }

        .remove-profile-button {
          height: 36px;
          color: var(--gray-600);
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-top: -11px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: var(--gray-700);
          }
        }

        .avatar-wrapper {
          border-radius: 10px;
          padding: 10px;
          background-color: var(--gray-200);

          .user-account-no-avatar {
            width: 256px;
            height: 256px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            background-color: var(--primary-25);

            svg {
              width: 55%;
              height: 55%;
            }
          }
        }
      }

      .account-fields {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        flex: 1;
        overflow-y: scroll;
        padding-bottom: 2px;

        .section-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: var(--gray-900);
          margin: 0;
          padding: 0;
        }
        .user-edit-field {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          width: 100%;

          .edit-field-title {
            color: var(--gray-700);
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }

          .edit-field {
            width: 100%;
            height: max-content;
            padding-right: 16px;

            .MuiTouchRipple-root {
              display: none;
            }

            .MuiFormGroup-root {
              height: 44px;
              width: 100%;
              flex-direction: row;

              .MuiButtonBase-root {
                color: var(--gray-300);
                margin-right: 0;

                &.Mui-checked {
                  color: var(--primary-600);
                }
              }

              .MuiIconButton-root:hover {
                background-color: transparent;
              }
            }

            .edit-field-text-area {
              border: 1px solid var(--gray-300);
              color: var(--gray-500);
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              resize: none;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
              height: 44px;
              width: 100%;
              overflow: hidden;

              fieldset {
                border: 1px solid var(--gray-300);
              }
            }

            .MuiFormControl-root.MuiTextField-root {
              height: 44px;
              width: 100%;

              .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
                height: 44px;

                &.Mui-disabled.Mui-disabled {
                  background: var(--gray-25);
                }

                input {
                  color: var(--gray-500);
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                }

                fieldset {
                  border: 1px solid var(--gray-300);
                }
              }
            }
          }
        }
      }

      /* width */
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px var(--gray-25);
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: var(--gray-50);
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: var(--gray-100);
      }
    }

    .edit-info-actions-wrapper {
      width: 100%;
      display: flex;
      column-gap: 12px;
      height: 44px;

      .action-button {
        min-width: 76px;
        padding: 8px 14px;
        border-radius: 4px;
        height: 44px;
        text-transform: none;
        width: max-content;
        box-shadow: 0 1px 2px rgba(16 24 40 / 5%);

        .MuiButton-label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .cancel-edit-button {
        color: var(--gray-700);
        background-color: white;
        border: 1px solid var(--gray-300);
        border: none;
        box-shadow: none;
        outline: none;
      }

      .save-edit-button {
        color: white;
        background-color: var(--primary-600);
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.backdrop-edit-info {
  z-index: 5 !important;
}

.backdrop-segment-analysis {
  z-index: 2 !important;
  background-color: transparent !important;
}

@media (max-width: 992px) {
  .my-account-container {
    padding: 16px 16px 0;
    justify-content: center;
    align-items: center;

    .my-account-header {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info-wrapper {
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .profile-info {
        .badge-wrapper {
          flex-direction: column;
          row-gap: 20px;
          justify-content: center;
          align-items: center;

          .user-credentials {
            padding: 0;
            justify-content: center;
            align-items: center;

            .user-name {
              text-align: center;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .print-badge-wrapper {
          display: none !important;
        }
      }

      .profile-stats {
        flex-direction: column;
        row-gap: 48px;
      }
    }

    .info-fields-display {
      .user-field {
        max-width: 100%;
      }
    }

    .process-info-wrapper {
      position: sticky;
      bottom: 0;
      background-color: white;
      width: calc(100% + 32px);
      display: flex;
      justify-content: space-between;
      box-shadow: 0 -15px 16px -4px rgb(16 24 40 / 10%);
      padding: 8px 16px;
      z-index: 1;
      flex-wrap: wrap;
      gap: 10px;

      button {
        flex: 1;
        height: 44px;
      }

      .print-badge-button-mobile {
        display: initial;
        flex: 1;

        .wallet-info {
          display: flex;
          justify-content: center;
          align-items: center;
          width: unset;
        }
      }

      .print-badge-button-mobile,
      .process-info-button {
        height: 44px;
      }
    }
  }

  .backdrop-edit-info {
    z-index: 5 !important;
    align-items: flex-end !important;
    backdrop-filter: none !important;
    background-color: transparent !important;
  }

  .edit-info-container {
    .edit-info-wrapper {
      border-radius: 0;
      width: 100%;
      height: calc(100% - 80px);
      overflow-y: scroll;
      padding: 24px 16px;

      .modal-content-wrapper {
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-bottom: 24px;
        overflow-y: unset;

        .account-fields {
          margin-top: 36px;
          overflow-y: unset;
          width: 100%;

          .user-edit-field {
            .edit-field {
              padding-right: 0;
            }
          }

          > :last-child {
            margin-bottom: 84px;
          }
        }
      }

      .edit-info-actions-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        padding: 36px 16px;
        box-shadow: 0 -12px 24px rgb(0 0 0 / 13%);

        .action-button {
          flex: 1;
        }
      }
    }
  }
}
